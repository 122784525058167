import React from "react";
import { useEffect } from "react";

import { MsalProvider, useMsal } from "@azure/msal-react";

import { msalInstance } from "./auth-config";
import { useTokenCookieExchange, useLogout } from "./api";

const BrowserWrapper = ({
  children,
}: {
  children: React.ReactChild | React.ReactChildren;
}) => {
  const { instance, accounts } = useMsal();
  const logout = useLogout();

  const exchangeTokenForCookie = useTokenCookieExchange();
  useEffect(() => {
    const fn = async () => {
      if (accounts[0]) {
        try {
          await exchangeTokenForCookie();
        } catch {
          logout();
        }
      }
    };
    fn();
  }, [instance, accounts, logout, exchangeTokenForCookie]);

  return <>{children}</>;
};

const AzureADWrapper = ({
  children,
}: {
  children: React.ReactChild | React.ReactChildren;
}) => {
  if (!window) {
    return <>{children}</>;
  }
  return (
    <MsalProvider instance={msalInstance}>
      <BrowserWrapper>{children}</BrowserWrapper>
    </MsalProvider>
  );
};

export default AzureADWrapper;
