import PQueue from "p-queue";

import { FetcherResponse } from "./types";
import { processError, NETWORK_NOT_AVAILABLE, SERVER_ERROR } from "./errors";
import buildRequest, { RequestType } from "./build-request";

const RequestsQueue = new PQueue({
  concurrency: 10,
  intervalCap: 10,
  interval: 1000,
});

const delay = (t: number) => new Promise((r) => setTimeout(r, t));

export async function makeFetchRequest<T = any>(
  url: string,
  method: RequestType = "GET",
  data?: { [key: string]: any } | string | number,
  timeout = 1000 * 60,
  files?: File | File[],
  onUploadProgress?: (progress: number) => any
): Promise<T> {
  let response: FetcherResponse = await RequestsQueue.add(() =>
    buildRequest(url, method, data, timeout, files, onUploadProgress)
  )
    .then((resp) => resp)
    .catch(processError);

  if (response.status === 999) {
    throw NETWORK_NOT_AVAILABLE;
  }

  if (response.status >= 500 && ![502, 503].includes(response.status)) {
    throw SERVER_ERROR;
  }

  let retries = 0;
  const startTime = new Date().valueOf();

  // Exponential backoff.
  while (
    [429, 502, 503, 999].includes(response.status) &&
    (new Date().valueOf() - startTime) / 1000 <= timeout
  ) {
    const jitter = Math.floor(Math.random() * 1000);
    const timeToWait = 1000 * Math.pow(2, retries);
    await delay(timeToWait + jitter);
    retries += 1;

    response = await await RequestsQueue.add(() =>
      buildRequest(url, method, data, timeout, files, onUploadProgress)
    )
      .then((resp) => resp)
      .catch(processError);
  }

  // Final error, after all the time has passed.
  if (response.status < 200 || response.status >= 400) {
    throw response.error;
  }

  return response.data;
}

export default makeFetchRequest;
