import React from "react";

import config from "config";
import FirebaseWrapper from "./firebase/Wrapper";
import AzureADWrapper from "./azure-ad/Wrapper";

const AuthWrapper = ({
  children,
}: {
  children: React.ReactChild | React.ReactChildren;
}) => {
  switch (config.IDENTITY_PROVIDER) {
    case "firebase":
      return <FirebaseWrapper>{children}</FirebaseWrapper>;
    case "azure-ad":
      return <AzureADWrapper>{children}</AzureADWrapper>;
    default:
      return <>{children}</>;
  }
};

export default AuthWrapper;
