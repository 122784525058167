import { useEffect } from "react";
import {
  useQuery,
  useMutation,
  useInfiniteQuery,
  QueryStatus,
} from "react-query";

import { makeFetchRequest } from "./fetcher";
import { ApiError } from "./types";
import { queryCache } from "./query-cache";

interface Entity {
  id: string;
}

interface KeyedEntity {
  key: string;
}

export async function shareEntity(
  baseUrl: string,
  entityId: string,
  permissions: { can_view?: string[]; can_use?: string[] }
) {
  const url = `${baseUrl}/${entityId}/change-access-permissions`;
  const resp = await makeFetchRequest(url, "POST", permissions);
  return resp;
}

export async function revokeEntityAccessPermissions(
  baseUrl: string,
  entityId: string,
  permissions: { can_view?: string[]; can_use?: string[] }
) {
  const url = `${baseUrl}/${entityId}/revoke-access-permissions`;
  const resp = await makeFetchRequest(url, "POST", permissions);
  queryCache.invalidateQueries(baseUrl);
  queryCache.invalidateQueries(`${baseUrl}/${entityId}`);
  return resp;
}

export function useDeleteEntity<T extends Entity>() {
  const [mutate] = useMutation(deleteEntity, {
    onMutate: ({ baseUrl, entityID, queryUrl }) => {
      queryUrl = queryUrl || baseUrl;
      const entities = queryCache.getQueryData(queryUrl) as T[][];
      const remainingEntities = (entities || []).map((l) =>
        l.filter((d) => d.id !== entityID)
      );
      queryCache.setQueryData(queryUrl, remainingEntities);
    },
  });

  return mutate;
}

interface DeleteProps {
  baseUrl: string;
  entityID: string;
  queryUrl?: string;
}

export const deleteEntity = async ({ baseUrl, entityID }: DeleteProps) => {
  const resp = await makeFetchRequest(`${baseUrl}/${entityID}`, "DELETE");
  return resp;
};

export function useFetchEntity<T extends Entity | KeyedEntity>(
  baseUrl: string,
  entityId: string | undefined,
  staleTime: number = 60 * 60 * 1000,
  cacheTime: number = 60 * 60 * 1000
) {
  return useQuery<T, ApiError>(`${baseUrl}/${entityId}`, makeFetchRequest, {
    staleTime,
    cacheTime,
    enabled: !!entityId,
  });
}

function fetchMultipleEntities<T extends Entity | KeyedEntity>(
  baseUrl: string,
  entityIds: string[]
) {
  const requests = Promise.all(
    entityIds.map(async (id) => {
      const url = `${baseUrl}/${id}`;
      const fromCache = queryCache.getQueryData(url) as T | undefined;
      if (fromCache) {
        return fromCache;
      }
      const resp = await makeFetchRequest<T>(url);
      queryCache.setQueryData(url, resp);
      return resp;
    })
  );

  return requests;
}

export function useFetchMultipleEntities<T extends Entity | KeyedEntity>(
  baseUrl: string,
  entityIds: string[],
  staleTime: number = 60 * 60 * 1000,
  cacheTime: number = 60 * 60 * 1000
) {
  return useQuery<T[], ApiError>([baseUrl, entityIds], fetchMultipleEntities, {
    staleTime,
    cacheTime,
    enabled: !!entityIds,
  });
}

interface ListingResult<T = any> {
  count: number;
  next?: string;
  previous?: string;
  results: T[];
}

async function fetchListing<T>(url: string, cursor: number = 0): Promise<T[]> {
  const requiredUrl = cursor
    ? `${url}${url.includes("?") ? "&" : "?"}offset=${cursor}`
    : url;
  const data = await makeFetchRequest<ListingResult<T>>(requiredUrl);
  return data.results;
}

const delay = (t: number) => new Promise((r) => setTimeout(r, t));

export function useFetchPaginatedData<T = any>(
  baseUrl: string,
  waitForAll: boolean = false
): [T[], QueryStatus] {
  const {
    status,
    data,
    isFetching,
    isFetchingMore,
    fetchMore,
    canFetchMore,
  } = useInfiniteQuery<T[]>(baseUrl, fetchListing, {
    getFetchMore: (lastGroup, allGroups) => {
      return lastGroup.length >= 10 ? allGroups.length * 10 : false;
    },
  });

  useEffect(() => {
    const fn = async () => {
      if (canFetchMore && !isFetching && !isFetchingMore) {
        // For whatever reason, use-infinite-query doesnt hit the cache.
        // And if there are two or more requests happening, there's chaos here.
        // Delaying by 100ms stops the chaos
        await delay(100);
        await fetchMore();
      }
    };
    fn();
  }, [canFetchMore, fetchMore, isFetchingMore, isFetching]);

  const finalStatus = waitForAll
    ? canFetchMore || isFetching || isFetchingMore
      ? QueryStatus.Loading
      : status
    : status;

  return [(data || []).flat(), finalStatus];
}
