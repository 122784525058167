const APP_CONFIG: { [key: string]: string } = (window as any).APP_CONFIG || {};

const ADMIN_CONTACT =
  APP_CONFIG.ADMIN_CONTACT ||
  process.env.REACT_APP_ADMIN_CONTACT ||
  "contact@daishohq.com";

const ALLOW_M2M_APPS = ["true", "True", true].includes(
  APP_CONFIG.ALLOW_M2M_APPS === undefined
    ? process.env.REACT_APP_ALLOW_M2M_APPS === undefined
      ? false
      : process.env.REACT_APP_ALLOW_M2M_APPS
    : APP_CONFIG.ALLOW_M2M_APPS
);

const LOGOUT_ON_SESSION_CLOSE = ["true", "True", true].includes(
  APP_CONFIG.LOGOUT_ON_SESSION_CLOSE === undefined
    ? process.env.REACT_APP_LOGOUT_ON_SESSION_CLOSE === undefined
      ? true
      : process.env.REACT_APP_LOGOUT_ON_SESSION_CLOSE
    : APP_CONFIG.LOGOUT_ON_SESSION_CLOSE
);

const ROUTER_BASE_URL = APP_CONFIG.BASE_PATH || process.env.PUBLIC_URL || "";

const IDENTITY_PROVIDER =
  process.env.REACT_APP_IDENTITY_PROVIDER ||
  APP_CONFIG.IDENTITY_PROVIDER ||
  "Daisho-JWT";
const SIGN_IN_ALLOWED = [true, "true"].includes(
  process.env.REACT_APP_ALLOW_SIGNIN || false
);
const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
const FIREBASE_DB_URL = process.env.REACT_APP_FIREBASE_DB_URL;
const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
const FIREBASE_MESSAGING_ID = process.env.REACT_APP_FIREBASE_MESSAGING_ID;
const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;
const FIREBASE_MEASUREMENT_ID = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;

const UPGRADE_URL = process.env.REACT_APP_UPGRADE_URL;

const API_BASE_URL =
  process.env.REACT_APP_DAISHO_API_URL || APP_CONFIG.DAISHO_API_URL;

const AUTH_API_URL =
  process.env.REACT_APP_AUTH_API_URL || APP_CONFIG.AUTH_API_URL;

const SENTRY_DSN =
  process.env.REACT_APP_DAISHO_SENTRY_DSN || APP_CONFIG.DAISHO_SENTRY_DSN;

export const BEACON_IDS = {
  landing: "c5b584b9-507a-498d-be08-c78245f70bd7",
  "data-files": "7b8bc203-d4c4-404f-9396-1d22ea9e09a7",
  workspaces: "e58d64d3-98cc-41a2-a9c6-875dfc6220f6",
};

const AZURE_AD_APPLICATION_ID = process.env.REACT_APP_AZURE_AD_APPLICATION_ID;
const AZURE_AD_AUTHORITY_URL = process.env.REACT_APP_AZURE_AD_AUTHORITY_URL;
const AZURE_AD_DIRECTORY_ID = process.env.REACT_APP_AZURE_AD_DIRECTORY_ID;
const AZURE_AD_APP_SECRET = process.env.REACT_APP_AZURE_AD_APP_SECRET;

const timeoutLimit =
  APP_CONFIG.IDLE_TIMEOUT_LIMIT || process.env.REACT_APP_IDLE_TIMEOUT_LIMIT;

const IDLE_TIMEOUT_LIMIT = timeoutLimit ? parseInt(timeoutLimit) : 24 * 60;

const config = {
  ADMIN_CONTACT,
  ALLOW_M2M_APPS,
  LOGOUT_ON_SESSION_CLOSE,
  IDLE_TIMEOUT_LIMIT,
  ROUTER_BASE_URL,
  IDENTITY_PROVIDER,
  SIGN_IN_ALLOWED,
  FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_DB_URL,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_ID,
  FIREBASE_APP_ID,
  FIREBASE_MEASUREMENT_ID,
  AZURE_AD_APPLICATION_ID,
  AZURE_AD_AUTHORITY_URL,
  AZURE_AD_DIRECTORY_ID,
  AZURE_AD_APP_SECRET,
  UPGRADE_URL,
  API_BASE_URL,
  AUTH_API_URL,
  SENTRY_DSN,
};

export default config;
