import * as React from "react";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/core/Skeleton";
/**
 *
 * @param props Take no props, just return skeleton while components load lazy
 */
function Loader() {
  const height = "50px";
  return (
    <Box p={2}>
      <Skeleton
        style={{ backgroundColor: "#cacaca" }}
        variant={"text"}
        height={height}
      />
      <Skeleton variant={"text"} height={height} />
      <Skeleton variant={"text"} width={"50%"} height={height} />
    </Box>
  );
}

export default Loader;
