import React from "react";

import { useInitFirebase } from "./init-firebase";

const FirebaseWrapper = ({ children }: { children?: React.ReactNode }) => {
  useInitFirebase();
  return <>{children}</>;
};

export default FirebaseWrapper;
