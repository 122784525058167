import * as React from "react";

import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import { ReactQueryCacheProvider } from "react-query";

import { Beforeunload } from "react-beforeunload";
import { useIdleTimer } from "react-idle-timer/dist/modern";

import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import { LocalizationProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import APP_CONFIG from "config";
import theme from "material-theme";
import { queryCache } from "api/query-cache";

import Loading from "ui-components/Loading-Component";

import AuthWrapper from "modules/auth/Wrapper";
import { useUserDetailsStore } from "modules/auth/store";

const Body = React.lazy(() => import("./Body"));

const W = () => {
  const logout = useUserDetailsStore((state) => state.logout);
  const idleTimeout = APP_CONFIG.IDLE_TIMEOUT_LIMIT;

  useIdleTimer({ timeout: idleTimeout * 1000 * 60, onIdle: logout });

  if (APP_CONFIG.LOGOUT_ON_SESSION_CLOSE) {
    return (
      <Beforeunload onBeforeunload={logout}>
        <Body />
      </Beforeunload>
    );
  }

  return <Body />;
};

function App() {
  return (
    <React.Suspense fallback={<Loading />}>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <BrowserRouter>
          <HelmetProvider>
            <LocalizationProvider dateAdapter={DateFnsUtils}>
              <ReactQueryCacheProvider queryCache={queryCache}>
                <AuthWrapper>
                  <W />
                </AuthWrapper>
              </ReactQueryCacheProvider>
            </LocalizationProvider>
          </HelmetProvider>
        </BrowserRouter>
      </ThemeProvider>
    </React.Suspense>
  );
}

export default App;
