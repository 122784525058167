import "react-app-polyfill/stable";
import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import * as Sentry from "@sentry/browser";

import { config } from '@fortawesome/fontawesome-svg-core';
import "@fortawesome/fontawesome-svg-core/styles.css"

// Make sure this is before any other `fontawesome` API calls
config.autoAddCss = false

const SENTRY_DSN = process.env.REACT_APP_DAISHO_SENTRY_DSN;

if (
  process.env.NODE_ENV === "production" &&
  process.env.REACT_APP_SENTRY_RELEASE &&
  SENTRY_DSN !== undefined &&
  SENTRY_DSN !== null
) {
  Sentry.init({
    dsn: SENTRY_DSN,
    release: process.env.REACT_APP_SENTRY_RELEASE,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
