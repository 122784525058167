import { useEffect } from "react";
import { useQuery } from "react-query";

import config from "config";
import api, { urls, queryCache } from "api";

import { UserDetails } from "types";

import azureLogout from "./azure-ad/logout";
import firebaseLogout from "./firebase/logout";
import { useUserDetailsStore } from "./store";

export const useFetchProfile = () => {
  const { data: userDetails, error, status } = useQuery<UserDetails>(
    urls.me,
    api,
    { retry: false }
  );

  const updateUserDetails = useUserDetailsStore(
    (store) => store.updateUserDetails
  );
  const resetUserDetails = useUserDetailsStore(
    (store) => store.resetUserDetails
  );

  useEffect(() => {
    if (status !== "loading" && status !== "idle") {
      if (error) {
        resetUserDetails();
      } else if (userDetails) {
        updateUserDetails(userDetails);
      } else {
        resetUserDetails();
      }
    }
  }, [userDetails, error, status, updateUserDetails, resetUserDetails]);

  return userDetails;
};

export const useLoginSubmit = () => {
  const http =
    urls.login.startsWith("http://") &&
    !urls.login.startsWith("http://localhost");
  return async (input: { email: string; password: string }) => {
    try {
      if (http) {
        localStorage.removeItem("jwt");
      }
      const { token } = await api(urls.login, "post", input);
      if (http) {
        localStorage.setItem("jwt", token);
      }
      return { success: true };
    } catch (e) {
      return { success: false };
    }
  };
};

export const logout = async () => {
  await api(urls.logout, "post");
  queryCache.removeQueries(urls.me);
  if (config.IDENTITY_PROVIDER === "firebase") {
    await firebaseLogout();
  }
  if (config.IDENTITY_PROVIDER === "azure-ad") {
    await azureLogout();
  }
};
