import { AxiosError } from "axios";
import { ApiError } from "./types";

import { resetUserDetails } from "modules/auth/store";

export const NETWORK_NOT_AVAILABLE: ApiError = {
  detail: "Network Not Available. Could you recheck and try later?",
};

export const SERVER_ERROR: ApiError = {
  detail:
    "Something went wrong on the server. We are very sorry for this. Maybe you want to try again later?",
};

export const LOGIN_FAILURE_MESSAGES: string[] = [
  "Invalid payload.",
  "Authentication credentials were not provided.",
];

export const processError = ({ response }: AxiosError) => {
  // Network error - no response from server.
  if (!response) {
    return { status: 999, error: { detail: "Network Issues. Try later." } };
  }

  // Server sent back an error
  const { status } = response;
  let error = response.data as ApiError;

  const errorMessage = error.detail as string;
  if (LOGIN_FAILURE_MESSAGES.includes(errorMessage)) {
    // handle login failure
    // update store
    localStorage.removeItem("jwt");
    resetUserDetails();
  } else if (!error.detail && !error.non_field_errors) {
    error = { detail: response.data };
  }

  return { status, error };
};
