import createHook from "zustand";
import createStore from "zustand/vanilla";

import { IPublicClientApplication, AccountInfo } from "@azure/msal-browser";

import * as firebase from "firebase/app";

import api, { urls, queryCache } from "api";

import { UserDetails } from "types";
import { logout } from "./api";

type State = {
  userDetails?: UserDetails;
  updateUserDetails: (user: UserDetails) => void;
  resetUserDetails: () => void;
  logout: () => void;

  updateFirebaseUser: (user: firebase.User) => void;
  updateADUser: (
    instance: IPublicClientApplication,
    account: AccountInfo
  ) => void;
};

export const userDetailsStore = createStore<State>((set, get) => ({
  userDetails: undefined,
  updateUserDetails: (userDetails) => set({ userDetails }),
  resetUserDetails: () => set({ userDetails: undefined }),
  logout: async () => {
    set({ userDetails: undefined });
    await logout();
  },

  updateFirebaseUser: async (user) => {
    // We need to get the session-token the moment user is updated.

    const userDetails = {
      displayName: user.displayName,
      email: user.email,
      emailVerified: user.emailVerified,
      photoURL: user.photoURL,
      uid: user.uid,
      phoneNumber: user.phoneNumber,
      providerData: user.providerData,
    };

    // Force refresh: Every call ensures the id-token is refreshed, and the user's still logged-in.
    const idToken = await user.getIdToken(true);
    try {
      const userDetailsDaisho = await api<UserDetails>(urls.me, "POST", {
        idToken,
        userDetails,
      });
      const currentUser = get().userDetails;
      if (currentUser?.email !== userDetailsDaisho.email) {
        set({ userDetails: userDetailsDaisho });
        queryCache.removeQueries(urls.me);
      }
    } catch (e) {
      await logout();
      set({ userDetails: undefined });
    }
  },

  updateADUser: async (instance, account) => {
    const token = await instance.acquireTokenSilent({
      scopes: ["User.Read"],
      account,
    });
    const idToken = token.idToken;
    try {
      const userDetailsDaisho = await api<UserDetails>(urls.me, "POST", {
        idToken,
      });
      const currentUser = get().userDetails;
      if (currentUser?.email !== userDetailsDaisho.email) {
        set({ userDetails: userDetailsDaisho });
        queryCache.removeQueries(urls.me);
      }
    } catch (e) {
      await logout();
      set({ userDetails: undefined });
    }
  },
}));

export const useUserDetailsStore = createHook(userDetailsStore);

export const resetUserDetails = () =>
  userDetailsStore.setState({ userDetails: undefined });

export const useGetUserDetails = () =>
  useUserDetailsStore((state) => state.userDetails);

export const useGetLoginStatus = () => !!useGetUserDetails();

export const useGetLicenseValidity = () => {
  const userDetails = useGetUserDetails();
  if (!userDetails) {
    return true;
  }
  const now = new Date();
  const expiry = new Date(userDetails.expiry_date);

  return expiry > now;
};
