import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    common: {
      black: "#4a4a4a",
      white: "#ffffff",
    },
    primary: {
      light: "#904fea",
      main: "#5b1eb7",
      dark: "#1e0086",
      contrastText: "#ffffff",
    },
    secondary: {
      light: "#adea55",
      main: "#7ab71e",
      dark: "#478700",
      contrastText: "#ffffff",
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#ffffff",
    },
    warning: {
      light: "#ffb74d",
      main: "#ff9800",
      dark: "#f57c00",
      contrastText: "#ffffff",
    },
    info: {
      light: "#64b5f6",
      main: "#2196f3",
      dark: "#1976d2",
      contrastText: "#ffffff",
    },
    success: {
      light: "#81c784",
      main: "#4caf50",
      dark: "#388e3c",
      contrastText: "#ffffff",
    },
    text: {
      primary: "#4a4a4a",
      secondary: "rgba(0, 0, 0, 0.54)",
    },
    action: {
      active: "rgba(0, 0, 0, 0.54)",
      hover: "rgba(0, 0, 0, 0.04)",
      hoverOpacity: 0.04,
      selected: "rgba(0, 0, 0, 0.28)",
      selectedOpacity: 0.08,
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(0, 0, 0, 0.12)",
      disabledOpacity: 0.38,
      focus: "rgba(0, 0, 0, 0.12)",
      focusOpacity: 0.12,
      activatedOpacity: 0.12,
    },
  },
  typography: {
    fontSize: 16,
    caption: { letterSpacing: "0.01071em" },
    button: { letterSpacing: 0 },
  },
});

export default theme;
