import { useEffect, useState } from "react";
import firebase from "firebase/app";
import "firebase/auth";

import config from "config";

import { useTokenCookieExchange } from "./api";

const firebaseConfig = {
  apiKey: config.FIREBASE_API_KEY,
  authDomain: config.FIREBASE_AUTH_DOMAIN,
  databaseURL: config.FIREBASE_DB_URL,
  projectId: config.FIREBASE_PROJECT_ID,
  storageBucket: config.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: config.FIREBASE_MESSAGING_ID,
  appId: config.FIREBASE_APP_ID,
  measurementId: config.FIREBASE_MEASUREMENT_ID,
};

export const useInitFirebase = () => {
  const [ready, setReady] = useState(false);
  const updateUser = useTokenCookieExchange();
  useEffect(() => {
    if (firebase.apps.length >= 1) {
      setReady(true);
    } else {
      const f = async () => {
        if (firebase.apps.length === 0) {
          await firebase.initializeApp(firebaseConfig);
        }
        setReady(true);
      };
      f();
    }
  }, []);

  useEffect(() => {
    if (ready) {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          updateUser?.(user);
        }
      });
    }
  }, [ready, updateUser]);

  // useEffect(() => {
  //   console.log(ready, updateUser);
  //   if (ready) {
  //     const unregisterAuthObserver = firebase
  //       .auth()
  //       .onAuthStateChanged((user) => {
  //         if (user) {
  //           updateUser?.(user);
  //           document.location.href = config.BASE_PATH || "/";
  //         }
  //       });

  //     return () => unregisterAuthObserver();
  //   }
  // }, [ready, updateUser]);
};

export default useInitFirebase;
