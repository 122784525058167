import JSON5 from "json5";
import { ComputeStatus, TaskStatus } from "types";

import urls from "./urls";
import { makeFetchRequest } from "./fetcher";
import { ApiError } from "./types";

type InsightResponse<T = unknown> = {
  data?: T;
  compute_status: ComputeStatus;
} & { request_id: string };

const delay = (t: number) => new Promise((r) => setTimeout(r, t));

const throwError = (error?: any) => {
  const err = new Error(error || "Unable to compute");
  throw err;
};

export async function compute<T extends {}>(
  url: string,
  data?: T,
  fetchDataAfterTaskCompletion?: false
): Promise<TaskStatus>;
export async function compute<T extends {}, R = any>(
  url: string,
  data?: T,
  fetchDataAfterTaskCompletion?: true
): Promise<R>;
export async function compute<T extends {}, R = any>(
  url: string,
  data?: T,
  fetchDataAfterTaskCompletion: boolean = true
): Promise<R | TaskStatus> {
  let resp = await makeFetchRequest<InsightResponse<R>>(url, "POST", data);

  if (typeof resp === "string") {
    try {
      resp = JSON5.parse(resp);
    } catch (e) {
      console.log("LINE 40", resp);
    }
  }

  if (!resp.request_id) {
    const { data, compute_status } = resp;
    // Received results.
    (compute_status.error || !data) && throwError(compute_status.error_message);
    return data as R;
  }

  // Task was queued. Now keep checking.
  const taskStatus = await fetchTaskStatus(resp.request_id);
  if (taskStatus.status === "FAILURE") {
    throwError(taskStatus.result?.error_message);
  }

  if (!fetchDataAfterTaskCompletion) {
    return taskStatus;
  }

  resp = await makeFetchRequest<InsightResponse<R>>(url, "POST", data);
  !resp.data && throwError(resp.compute_status.error_message);
  return resp.data as R;
}

export const fetchTaskStatus = async (taskId: string) => {
  const taskUrl = `${urls.task_status}/${taskId}`;

  let taskStatus: TaskStatus = { status: "IN PROGRESS" };

  while (!["FAILURE", "SUCCESS"].includes(taskStatus.status)) {
    try {
      taskStatus = (await makeFetchRequest(taskUrl)) as TaskStatus;
      if (taskStatus.status === "FAILURE") {
        return taskStatus;
      }

      if (taskStatus.status === "SUCCESS") {
        // Check if there was a failure
        const { result } = taskStatus;
        if (result?.error) {
          return {
            status: "FAILURE",
            result: {
              completed: false,
              error: true,
              error_message:
                result?.error_message || result?.message || "Unable to compute",
              data: null,
            },
          } as TaskStatus;
        }
      }
      // In progress. Wait for 2 seconds, and check again.
      await delay(2000);
    } catch (e) {
      console.log(e);
      return {
        status: "FAILURE",
        result: {
          completed: false,
          error: true,
          error_message: e?.detail || "Unable to compute",
          data: null,
        },
      } as TaskStatus;
    }
  }

  return taskStatus;
};

export const processError = (
  error:
    | ApiError
    | ComputeStatus["error_message"]
    | TaskStatus["result"]
    | { [key: string]: string[] | string }
): string | string[] | undefined => {
  if (!error) {
    return;
  }
  if (typeof error === "string" || Array.isArray(error)) {
    return error;
  }

  const { detail, non_field_errors } = error as ApiError;

  if (detail || non_field_errors) {
    let errorMessages: string[] = [];
    if (detail) {
      if (typeof detail === "string") {
        errorMessages.push(detail);
      } else {
        Object.keys(detail).forEach((k) => {
          const e = detail[k];
          if (typeof e === "string") {
            errorMessages.push(`${k}: ${e}`);
          } else {
            errorMessages.push(...e.map((s) => `${k}: ${s}`));
          }
        });
      }
    }

    if (non_field_errors) {
      if (typeof non_field_errors === "string") {
        errorMessages.push(non_field_errors);
      } else {
        errorMessages.push(...non_field_errors);
      }
    }

    return errorMessages;
  }

  const { error: errorB, error_message, message } = error as NonNullable<
    TaskStatus["result"]
  >;

  if (errorB === true) {
    return error_message || message || "Unable to Compute";
  }

  // if (Object.is)

  return;
};
