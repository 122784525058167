import { useCallback } from "react";

import firebase from "firebase/app";
import "firebase/auth";

import { queryCache } from "api";

import { useUserDetailsStore } from "../store";

export const useTokenCookieExchange = () => {
  const updateUserDetails = useUserDetailsStore(
    (actions) => actions.updateFirebaseUser
  );

  return useCallback(
    async (user: firebase.User) => {
      updateUserDetails(user);
    },
    [updateUserDetails]
  );
};

export const useLogout = () => {
  const logout = useUserDetailsStore((actions) => actions.logout);

  return useCallback(async () => {
    queryCache.clear();
    try {
      await firebase.auth().signOut();
    } catch (e) {}
    try {
      await logout();
    } catch (e) {}
  }, [logout]);
};
